declare global {
  interface Window {
    grecaptcha: any;
  }
}

import { useState, useEffect, useRef, useMemo } from 'react';
import type { AgentList, AgentFilters } from 'utils/agent-endpoint';
import InputWithCountrySelect from './input-with-country-select/InputWithCountrySelect';
import InputWithSearchIcon from './input-with-search-icon/InputWithSearchIcon';
import PaginationNav from 'components/control/pagination-nav';
import * as AgentActions from 'utils/agent-endpoint';
import { CountryCodeList } from 'types/countries';
import { PAGE_SIZE, AgentType } from 'utils/agent-endpoint';
import AgentCard from './agent-card/AgentCard';
import { useRouter } from 'next/router';
import NextImage from 'next/image';
import Script from 'next/script';
import { trackEvent } from 'utils/google-tag-manager';
import { GTM_VISIT_AGENT_SEARCH_PAGE } from 'constants/events';
import { agentSearchIds } from 'constants/test-constants';
import { isClientSide } from 'utils/host-config';
import SkeletonAgentCard from './agent-card/SkeletonAgentCard';
import SkeletonResultCount from './skeleton/SkeletonResultCount';
import { generateAgentStyles } from 'utils/agent-name-obfuscator';
import AgentFilterDropdown from './agent-filter-dropdown/AgentFilterDropdown';

import styles from './style.module.scss';
import { HeadDataType } from 'components/head-data';
import type { AgentSearchConfig } from 'tenants';
import { useIsMobile } from 'hooks/use-size-class';
import { ThemeNames as TenantNames } from 'types/themes';

/**
 * Agent search page props
 * @param {AgentList[]} featureAgents - List of featured agents initially displayed on the page
 * @param {CountryCodeList} initialCountry - If URL query contained country code or we get it from site location
 * @param {string} sessionId - sessionId used for agent pagination performance
 * @param {HeadDataType} headerData - Header data for the page
 * @param {AgentType} agentType - Type of search page (Agent or Commercial)
 * @param {TenantNames} tenantName - Current tenant name
 */
export interface AgentSearchProps {
  initialCountry: CountryCodeList;
  sessionId: string;
  headerData: HeadDataType;
  agentType: AgentType;
  tenantName: TenantNames;
  config?: AgentSearchConfig;
}

interface AgentQueryParams {
  [key: string]: string | undefined;
  page: string;
  country: string;
  location?: string;
  name?: string;
  m?: string; // 'm' for military filter, 't' for true
}

// Add session storage key for filter preference
const FILTER_CHANGED_KEY = 'agentFilterChanged';

const AgentSearch = ({ initialCountry, sessionId, agentType = AgentType.RESIDENTIAL, config, tenantName }: AgentSearchProps) => {
  const router = useRouter();

  const shouldFetchFeatured = useRef(true);
  const shouldFetchAgents = useRef(true);
  const isCommercial = agentType === AgentType.COMMERCIAL;
  const country = router.query.country as CountryCodeList || initialCountry;
  const selectedLocation = router.query.location ? decodeURIComponent(router.query.location as string) : '';

  const [featuredAgents, setFeaturedAgents] = useState<AgentList[]>([]);
  const [scriptLoaded, setScriptLoaded] = useState(isClientSide() && typeof window.grecaptcha !== 'undefined');
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(() => parseInt(router.query.page as string, 10) || 1);
  const [locationQuery, setLocationQuery] = useState(selectedLocation);
  const [agents, setAgents] = useState<AgentList[]>([]);
  const [selectedName, setSelectedName] = useState(() =>
    router.query.name ? decodeURIComponent(router.query.name as string) : '');
  const [nameQuery, setNameQuery] = useState(selectedName);
  const [isLoading, setIsLoading] = useState(false);
  const isFeatured = !selectedLocation && !selectedName && !isCommercial;
  const [agentStyles, setAgentStyles] = useState<string>('');
  const isMobile = useIsMobile();
  const [filters, setFilters] = useState<AgentFilters>(() => {
    // First check if we have a URL parameter
    if (router.query.m !== undefined) {
      return {
        isMilnet: router.query.m === 't',
      };
    }

    // Check if user has changed filter before (stored in session storage)
    if (isClientSide() && sessionStorage.getItem(FILTER_CHANGED_KEY) === 'true') {
      // Get the last known preference from session storage
      const lastPreference = sessionStorage.getItem('agentMilnetPreference');
      console.log('lastPreference', lastPreference);
      if (lastPreference !== null) {
        return {
          isMilnet: lastPreference === 'true',
        };
      }
    }

    // If no URL parameter and no saved preference, use tenant-based default only for InService
    return {
      isMilnet: tenantName === TenantNames.EXP_IN_SERVICE,
    };
  });

  const countryName = country === CountryCodeList.CANADA ? 'Canada' : 'US';
  const defaultAgentName = 'eXp Agent';
  // Do no show country select for the InService and Commercial tenants
  const showCountrySelect = ![TenantNames.EXP_IN_SERVICE, TenantNames.EXP_COMMERCIAL].includes(tenantName as any);

  // Use customized header title for InService tenant or fall back to config
  const headerTitle = (() => {
    if (tenantName === TenantNames.EXP_IN_SERVICE && filters.isMilnet) return 'Your Military Move Experts \n We Are Here For You';
    return config?.pageTitle ?? `Search All ${!isCommercial ? countryName + ' ' : ''}${config?.agentTypeName ?? defaultAgentName}s`;
  })();

  const updatePathQuery = (state: any = null) => {
    if (!state) return;
    let params: AgentQueryParams = {
      page: page.toString(),
      country,
      m: filters.isMilnet ? 't' : 'f',
    };

    if (locationQuery !== '') params.location = locationQuery;
    if (nameQuery !== '') params.name = nameQuery;

    if (state) params = { ...params, ...state };

    const currentQuery = router.query;
    const queryChanged =
      currentQuery.country !== params.country ||
      currentQuery.page !== params.page ||
      currentQuery.m !== params.m ||
      currentQuery.location !== params.location ||
      currentQuery.name !== params.name;

    if (queryChanged) {
      if (params.location === '') delete params.location;
      if (params.name === '') delete params.name;
      if (!params.m) delete params.m;

      router.push(
        {
          pathname: router.pathname,
          query: params,
        },
        undefined,
        { shallow: true }
      );
    }
  };

  const updateCountry = (country: CountryCodeList) => {
    setLocationQuery('');
    setNameQuery('');
    setPage(1);
    shouldFetchAgents.current = true;
    updatePathQuery({ country, location: '', name: '', page: '1' });
  };

  const handleSelectLocation = (location: string) => {
    setLocationQuery(location);
    setNameQuery('');
    setPage(1);
    shouldFetchAgents.current = true;
    updatePathQuery({ location, name: '', page: '1' });
  };

  const handleSelectName = (name: string) => {
    setNameQuery(name);
    setLocationQuery('');
    setPage(1);
    shouldFetchAgents.current = true;
    updatePathQuery({ name, location: '', page: '1' });
  };

  const handleFilterChange = (newFilters: AgentFilters) => {
    console.log('### handleFilterChange', newFilters);
    setLocationQuery('');
    setNameQuery('');
    setFilters(newFilters);
    setPage(1);
    shouldFetchAgents.current = true;

    // Save to session storage that user has changed the filter
    if (isClientSide()) {
      sessionStorage.setItem(FILTER_CHANGED_KEY, 'true');
      sessionStorage.setItem('agentMilnetPreference', String(newFilters.isMilnet));
    }

    updatePathQuery({
      location: '',
      name: '',
      m: newFilters.isMilnet ? 't' : 'f',
      page: '1',
    });
  };

  const AgentHeader = useMemo(() => {
    return (
      <div className={`${styles['agent-header']}`}>
        <div className={`${styles['exp-header-banner']}`}>
          <NextImage
            key="exp-banner-white-no-text-1"
            fill
            src={isMobile ? config?.headerImage.mobileSrc ?? '/next/assets/images/agent-search/exp-banner-white-no-text.png' : config?.headerImage.src ?? '/next/assets/images/agent-search/exp-banner-white-no-text.png'}
            alt={config?.headerImage.alt ?? 'eXp Realty Logo'}
            priority={true}
          />
        </div>
        <h1 className={styles['agent-header-title']} data-testid={agentSearchIds.agentSearchHeader}>
          {headerTitle}
        </h1>

        <div className={styles.inputs}>
          <InputWithCountrySelect
            onCountryChange={updateCountry}
            country={country}
            onLocationSelect={handleSelectLocation}
            locationQuery={locationQuery}
            updateLocationQuery={(query: string) => setLocationQuery(query)}
            allowCountryChange={showCountrySelect}
          />

          <InputWithSearchIcon
            onSelectName={handleSelectName}
            country={country}
            nameQuery={nameQuery}
            isCommercial={isCommercial}
            filters={filters}
          />

          {/* Since this still is test feature, we need to hide it for all tenants except InService */}
          {tenantName === TenantNames.EXP_IN_SERVICE &&
          <AgentFilterDropdown
            selectedFilters={filters}
            onFilterChange={handleFilterChange}
            isCommercial={isCommercial}
          />}
        </div>
      </div>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isMobile,
    config,
    headerTitle,
    country,
    locationQuery,
    nameQuery,
    isCommercial,
    filters,
  ]);

  useEffect(() => {
    const fetchFeaturedAgents = async () => {
      if (isCommercial || isLoading) return;
      setIsLoading(true);
      shouldFetchFeatured.current = false;
      const response = await AgentActions.featuredAgents(country, undefined, filters);
      if (!(response instanceof Error)) {
        setFeaturedAgents(response.agents);
      }
      setIsLoading(false);
    };

    if (!selectedLocation && !isCommercial && scriptLoaded && isFeatured && shouldFetchFeatured.current) {
      fetchFeaturedAgents();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, scriptLoaded, filters]);

  useEffect(() => {
    const fetchAgents = async () => {
      if (isLoading) return;
      shouldFetchAgents.current = false;
      setIsLoading(true);
      const params = {
        name: selectedName,
        location: selectedLocation,
        country,
        page,
        session_id: sessionId,
        agentType,
        filters,
      };
      const data = await AgentActions.searchAgents(params);

      if (!(data instanceof Error)) {
        setAgents(data.agents);
        setCount(data.count);
      }
      setIsLoading(false);
    };

    if (scriptLoaded && shouldFetchAgents.current && router.isReady
      && (selectedName.length > 0 || selectedLocation.length > 0 || isCommercial)) {
      updatePathQuery();
      fetchAgents();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedName, selectedLocation, page, scriptLoaded, filters]);

  useEffect(() => {
    if (isFeatured && !isCommercial) trackEvent(GTM_VISIT_AGENT_SEARCH_PAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const combined = [...featuredAgents, ...agents];
    if (combined.length) {
      setAgentStyles(generateAgentStyles(combined));
    }
  }, [featuredAgents, agents]);

  const handleUpdatePageNumber = (pageNumber: number) => {
    shouldFetchAgents.current = true;
    setPage(pageNumber);
  };

  const renderCount = (): string => {
    if (isFeatured) {
      const descriptor = filters.isMilnet ? config?.featuredAgentDescriptor : 'eXp';
      return `16 Featured ${country} ${descriptor} Agents`;
    }
    if (isCommercial) return `${config?.agentTypeName ?? 'Commercial Advisor'}s`;
    if (selectedLocation.length > 0) {
      const location = selectedLocation.toLowerCase();
      if (location === 'washington, dc') {
        return `${count} District of Columbia ${config?.agentTypeName ?? defaultAgentName}${count !== 1 ? 's' : ''}`;
      }
      return `${count} ${selectedLocation.split(',')[0]} ${config?.agentTypeName ?? defaultAgentName}${count !== 1 ? 's' : ''}`;
    }
    return `${config?.agentTypeName ?? defaultAgentName}${count === 1 ? '' : 's'}`;
  };

  return (
    <div className={styles.component}>
      {agentStyles && <style dangerouslySetInnerHTML={{ __html: agentStyles }} />}
      {AgentHeader}

      {(!scriptLoaded || isLoading) && (
        <>
          <SkeletonResultCount />
          <div className={styles['search-results-container']}>
            {[...Array(4)].map((_, index) => (
              <SkeletonAgentCard key={index} />
            ))}
          </div>
        </>
      )}

      {(scriptLoaded && !isLoading) && (
        <>
          <p className={styles['result-count']} data-testid={agentSearchIds.agentsResultCount}>{renderCount()}</p>
          <div className={styles['search-results-container']}>
            {featuredAgents && featuredAgents.length > 0 && isFeatured && (
              <>
                {featuredAgents.map((agent, index) => (
                  <AgentCard key={agent.id || index} agent={agent} isCommercial={isCommercial} />
                ))}
              </>
            )}

            {(agents && Array.isArray(agents) && agents.length > 0) &&
              agents.map((agent, index) => (
                <AgentCard key={agent.id || index} agent={agent} isCommercial={isCommercial} />
              ))}
          </div>
          {(!agents || agents.length === 0) && !isFeatured && (
            <div className={styles['no-results']} data-testid={agentSearchIds.agentNoResultsMessage}>
              <NextImage
                src={`/next/assets/images/agent-search/no-agent-results${isCommercial ? '-commercial' : ''}.png`}
                alt="No results found"
                width={237}
                height={176}
              />
              <h1>We couldn't find any matches for your search.</h1>
              <p>Check for misspelled words or try a different search term.</p>
            </div>
          )}
        </>
      )}
      <PaginationNav
        pageNumber={page}
        totalPages={Math.ceil(count / PAGE_SIZE) || 1}
        setPageNumber={handleUpdatePageNumber}
      />
      {!scriptLoaded &&
        <Script src={`https://www.google.com/recaptcha/enterprise.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_KEY}`}
          onLoad={() => setScriptLoaded(true)}
        />
      }
    </div>
  );
};

export default AgentSearch;
