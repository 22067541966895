import useOutsideClickHandler from 'hooks/use-outside-click-handler';
import styles from './style.module.scss';
import { trackEvent } from 'utils/google-tag-manager';
import { GTM_CLICK_AGENT_PAGE_SUGGESTED_LOCATIONS, GTM_CLICK_AGENT_PAGE_SUGGESTED_NAMES } from 'constants/events';
import { agentSearchIds } from 'constants/test-constants';
import { buildClassName } from 'utils/build-class-name';

export interface SuggestionDropdownProps {
  suggestions: string[];
  type: string;
  activeSuggestion?: string;
  onSelect: (suggestion: string) => void;
  onClose?: () => void;
}

export default function SuggestionDropdown({ suggestions, type, activeSuggestion, onSelect, onClose }: SuggestionDropdownProps) {
  const outsideClickHandler = useOutsideClickHandler<HTMLDivElement>(() => onClose?.());

  const handleClickEvent = () => {
    if (type === 'location') {
      trackEvent(GTM_CLICK_AGENT_PAGE_SUGGESTED_LOCATIONS);
    } else if (type === 'name') {
      trackEvent(GTM_CLICK_AGENT_PAGE_SUGGESTED_NAMES);
    }
    // We don't need to track filter selection events yet, but could add them here
  };

  return (
    <div className={styles['suggestions-container']} ref={outsideClickHandler}>
      {suggestions.map((s, index) => (
        <div
          onClick={() => {
            handleClickEvent();
            onSelect(s);
          }}
          key={index}
          className={buildClassName(styles['suggestion'], s === activeSuggestion && styles.active)}
          data-testid={agentSearchIds.agentSearchDropdownOption}
        >
          {s}
        </div>
      ))}
    </div>
  );
}