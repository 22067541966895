import React, { useState, useRef } from 'react';
import { AgentFilters } from 'utils/agent-endpoint';
import useOutsideClickHandler from 'hooks/use-outside-click-handler';
import styles from './style.module.scss';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { buildClassName } from 'utils/build-class-name';
import { SuggestionDropdown } from 'components/agent-search';

interface FilterOption {
  label: string;
  filters: AgentFilters;
}

interface AgentFilterDropdownProps {
  selectedFilters: AgentFilters;
  onFilterChange: (filters: AgentFilters) => void;
  isCommercial?: boolean;
}

export default function AgentFilterDropdown({
  selectedFilters,
  onFilterChange,
  isCommercial = false,
}: AgentFilterDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);

  useOutsideClickHandler<HTMLDivElement>(() => setIsOpen(false));

  const options: FilterOption[] = [
    { label: 'All Agents', filters: { isMilnet: false }},
    { label: 'Military Agents', filters: { isMilnet: true }},
  ];

  const getSelectedLabel = () => {
    return selectedFilters.isMilnet ? 'Military Agents' : 'All Agents';
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (label: string) => {
    const option = options.find(opt => opt.label === label);
    if (option) {
      onFilterChange(option.filters);
    }
    setIsOpen(false);
  };

  const filterOptions = options.map(option => option.label);

  return (
    <div className={buildClassName(styles['filter-dropdown'], isCommercial && styles['is-commercial'])}>
      <div
        className={buildClassName(styles['dropdown-header'], isOpen && styles['active'])}
        onClick={toggleDropdown}
        data-testid="agent-filter-dropdown"
      >
        <span>{getSelectedLabel()}</span>
        <ArrowDropDownIcon />
      </div>

      {isOpen && (
        <SuggestionDropdown
          suggestions={filterOptions}
          type="filter"
          activeSuggestion={getSelectedLabel()}
          onSelect={handleSelect}
          onClose={() => setIsOpen(false)}
        />
      )}
    </div>
  );
}
